import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Did you know that there are over 100 different HTML elements? We're all familiar with common elements
like `}<InlineCode mdxType="InlineCode">{`<`}{`h1`}{`>`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`<`}{`p`}{`>`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`<`}{`img`}{`>`}</InlineCode>{`.
But many elements lead a niche existence. Some web developers don't even know they exist.`}</p>
    <p>{`That's why in this article I'd like to bring three underrated HTML elements to the
fore: `}<InlineCode mdxType="InlineCode">{`<`}{`time`}{`>`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`<`}{`picture`}{`>`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFO2dQRBOI//8QAGxABAAICAwAAAAAAAAAAAAAAAgEDAAQSEzL/2gAIAQEAAQUCoiWnitrE69s8n5A7c//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB8QAAEDAwUAAAAAAAAAAAAAAAEAAhEQEhMhMUFRYf/aAAgBAQAGPwLI7WNqQZnxBnCJ6Vxcv//EABoQAQACAwEAAAAAAAAAAAAAAAEAETFBUSH/2gAIAQEAAT8hve9EyKzqEmFLSBtpWHkSE0uKH0Xyf//aAAwDAQACAAMAAAAQlz//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxAn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBYVFxgf/aAAgBAQABPxDjSF+3l9EuplVt8jYIQLBYH+E0PXsigUpjtocXEvDY7cD57n//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A desk calendar showing the month of January. There's a pair of glasses on the calendar.",
          "title": "A desk calendar showing the month of January. There's a pair of glasses on the calendar.",
          "src": "/static/4688707652f1c2add3e575badda8d812/e5166/pexels-olya-kobruseva-calendar.jpg",
          "srcSet": ["/static/4688707652f1c2add3e575badda8d812/f93b5/pexels-olya-kobruseva-calendar.jpg 300w", "/static/4688707652f1c2add3e575badda8d812/b4294/pexels-olya-kobruseva-calendar.jpg 600w", "/static/4688707652f1c2add3e575badda8d812/e5166/pexels-olya-kobruseva-calendar.jpg 1200w", "/static/4688707652f1c2add3e575badda8d812/b17f8/pexels-olya-kobruseva-calendar.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Olya Kobruseva / pexels.com`}</em></p>
    <h2>{`The time element: Machine-readable dates`}</h2>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`<`}{`time`}{`>`}</InlineCode>{` `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time"
      }}>{`HTML element`}</a>{`
represents a specific point in time, date, or period. Together with the `}<InlineCode mdxType="InlineCode">{`datetime`}</InlineCode>{` attribute,
the date is provided in a machine-readable format. What's that good for? Here's an example:`}</p>
    <iframe scrolling="no" title="Code example for the time element" src="https://codepen.io/alexlehner86/embed/PoOGRwZ?default-tab=result" loading="lazy" allowtransparency="true">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/PoOGRwZ">
    time element German</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`The example above shows three events, each with a date displayed in a tear-off calendar style. Maybe your thinking:
Dude, that's self-explanatory! Together with the heading "Events 2022" it's clear what the precise dates are.`}</p>
    <p>{`Sighted people will probably have no problem with this representation of the date. But that's not true for blind or
visually impaired users. When the screen reader announces "mar four" or "jul eight", it will be confusing.
Use the `}<InlineCode mdxType="InlineCode">{`<`}{`time`}{`>`}</InlineCode>{` element to provide clarity.`}</p>
    <p>{`Unfortunately, not all popular screen readers support the element. `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/VoiceOver"
      }}>{`VoiceOver`}</a>{`
correctly reads out the date defined in the `}<InlineCode mdxType="InlineCode">{`datetime`}</InlineCode>{` attribute (tested with iPhone 8, iOS 15,
Safari). Sadly, it doesn't work with NVDA and TalkBack at the moment.`}</p>
    <h2>{`The picture element: Load images based on screen size`}</h2>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`<`}{`picture`}{`>`}</InlineCode>{` `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture"
      }}>{`HTML element`}</a>{`
is an image container that enables you to offer alternative versions of an image for different display/device scenarios.
It contains one `}<InlineCode mdxType="InlineCode">{`<`}{`img`}{`>`}</InlineCode>{` element and usually several `}<InlineCode mdxType="InlineCode">{`<`}{`source`}{`>`}</InlineCode>{` elements.
Consider the following example:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<picture>
    <source srcset="cat_lg.avif" type="image/avif" media="(min-width: 60rem)">
    <source srcset="cat_md.avif" type="image/avif">
    <source srcset="cat_lg.webp" type="image/webp" media="(min-width: 60rem)">
    <source srcset="cat_md.webp" type="image/webp">
    <source srcset="cat_lg.jpg" media="(min-width: 60rem)">
    <img src="cat_md.jpg" alt="An adorable cat" loading="lazy" decoding="async">
</picture>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`type`}</InlineCode>{` attribute specifies a MIME type for the resource defined in the `}<InlineCode mdxType="InlineCode">{`srcset`}</InlineCode>{`
attribute. In the example above, the first `}<InlineCode mdxType="InlineCode">{`<`}{`source`}{`>`}</InlineCode>{` element points to an image in the modern
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/AVIF"
      }}>{`AVIF format`}</a>{`. The browser will pick this image if it's capable of rendering AVIF images.
Otherwise, it moves on to the next `}<InlineCode mdxType="InlineCode">{`<`}{`source`}{`>`}</InlineCode>{` element.`}</p>
    <p>{`This allows modern browsers that support AVIF or `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/WebP"
      }}>{`WebP format`}</a>{` to load a highly compressed,
high-quality image. Older browsers can access the JPG version of the image instead.`}</p>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`media`}</InlineCode>{` attribute specifies a media query that the browser will evaluate. In the example we're
telling the browser that if the viewport width is wider than 60 rem it must use the large image. If the media query
evaluates to false, the browser skips it and evaluates the next `}<InlineCode mdxType="InlineCode">{`<`}{`source`}{`>`}</InlineCode>{` element.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFsrZJbBAf/xAAdEAABAwUBAAAAAAAAAAAAAAABAhEhAAMSEzEz/9oACAEBAAEFAtEr9AXFzuD0mB//xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAQQDAAAAAAAAAAAAAAAAAAERIVEiMWH/2gAIAQEABj8CTOaHoc6So2z/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUGR4f/aAAgBAQABPyFBeLGdMEoVfUQB2C74ppTUVU8SYfDrP//aAAwDAQACAAMAAAAQFy//xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQMBAT8QxIn/xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8QrH//xAAbEAEBAQACAwAAAAAAAAAAAAABEQAhUTFBkf/aAAgBAQABPxCWWhCuw3Fx7JcZwACVmpgNeQzmWL5Azu9ABZ83/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A sleeping cat with black-grey fur.",
          "title": "A sleeping cat with black-grey fur.",
          "src": "/static/5c6b65462e663749620e7bc2f45eef32/e5166/pexels-ihsan-adityawarman-sleeping-cat.jpg",
          "srcSet": ["/static/5c6b65462e663749620e7bc2f45eef32/f93b5/pexels-ihsan-adityawarman-sleeping-cat.jpg 300w", "/static/5c6b65462e663749620e7bc2f45eef32/b4294/pexels-ihsan-adityawarman-sleeping-cat.jpg 600w", "/static/5c6b65462e663749620e7bc2f45eef32/e5166/pexels-ihsan-adityawarman-sleeping-cat.jpg 1200w", "/static/5c6b65462e663749620e7bc2f45eef32/b17f8/pexels-ihsan-adityawarman-sleeping-cat.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Ihsan Adityawarman / pexels.com`}</em></p>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`srcset`}</InlineCode>{` attribute offers another option for image optimization. You can also use it together
with an `}<InlineCode mdxType="InlineCode">{`<`}{`img`}{`>`}</InlineCode>{` element, as shown in the cute cat picture above. Its HTML code looks something like this:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<img
    srcset="cat-480w.jpg 480w, cat-800w.jpg 800w"
    sizes="(max-width: 600px) 480px, 800px"
    src="cat-800w.jpg"
    alt="A sleeping cat with black-grey fur."
>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`srcset`}</InlineCode>{` attribute defines a set of images we will allow the browser to choose between,
and what size each image is. The `}<InlineCode mdxType="InlineCode">{`sizes`}</InlineCode>{` attribute defines a set of conditions (e.g. screen widths)
and indicates what image size would be best to choose, when certain media queries evaluate to true.`}</p>
    <p>{`If you want to delve deeper into the topic, I can recommend the following articles:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images"
        }}>{`Responsive Images (MDN Web Docs)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.dev/learn/design/picture-element/"
        }}>{`The picture element (web.dev)`}</a></li>
    </ul>
    <h2>{`Markup key-value pairs with dl, dt and dd`}</h2>
    <p>{`Lists are one of the basic building blocks of HTML. Every web developer knows the HTML
elements `}<InlineCode mdxType="InlineCode">{`<`}{`ul`}{`>`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`<`}{`ol`}{`>`}</InlineCode>{` for
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/ul"
      }}>{`unordered`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/ol"
      }}>{`ordered lists`}</a>{`.
Yet few are familiar with their close relative `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{`.`}</p>
    <p>{`The so called `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl"
      }}>{`description list`}</a>{` contains a list
of terms and descriptions that are defined with the HTML elements `}<InlineCode mdxType="InlineCode">{`<`}{`dt`}{`>`}</InlineCode>{`
and `}<InlineCode mdxType="InlineCode">{`<`}{`dd`}{`>`}</InlineCode>{`. Common uses are to display a glossary or metadata (a list of key-value pairs).`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<dl>
    <dt>Account Owner</dt>
    <dd>Martina Musterfrau</dd>
    <dt>Banking Institute</dt>
    <dd>Musterbank</dd>
    <dt>IBAN</dt>
    <dd>AT12 3456 7891 2345 6789</dd>
</dl>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Why do I think this element is awesome? You guessed it! It conveys the content in a programmatic way that screen
readers can understand. But web developers also benefit from it. Think of the example above as a sequence
of `}<InlineCode mdxType="InlineCode">{`<`}{`div`}{`>`}</InlineCode>{` elements. Which code block is more readable and easier to navigate?
`}<strong parentName="p">{`Semantic HTML makes code more readable!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      